import { useContext, useState } from "react";
import { globalContext } from "../GlobalStore";
import RequestTable from "./RequestTable";

function Requests() {
    
    const { globalState, dispatch } = useContext(globalContext);
    const [requests, setRequests] = useState<any>(null)
    const [error, setError] = useState<string>('')

    const onClick =  (() => {
        if (globalState.url) {
            console.log("about to call " + globalState.url + ':' + globalState.port + "/__admin/requests")
            fetch(globalState.url + ':' + globalState.port + "/__admin/requests")
                .then((response) => response.json())
                .then((data) => setRequests(data.requests))
                .catch((err) => {
                    console.log("Error getting requests:")
                    console.log(err.message);
                   });
        }

    })
    const onClickClear =  (() => {
            if (globalState.url) {
                console.log("about to delete from " + globalState.url + ':' + globalState.port + "/__admin/requests")
                fetch(globalState.url + ':' + globalState.port + "/__admin/requests", {
                    method: 'DELETE',
                    headers: {'Content-Type': 'application/json'},
                })
                    .then(() => onClick())
                    .catch((err) => {
                        console.log("Error deleting requests:")
                        console.log(err.message);
                       });
            }

        })

        var result = ""
        if (requests) {
            result = JSON.stringify(requests)
        }
    
    return (
        <div>
        <h1>WIREMOCK REQUEST LOG</h1>
        <button onClick={onClick}>Refresh</button>
        <button onClick={onClickClear}>Clear</button>
            
            <RequestTable requests={requests}/>
        </div>
    )
}

export default Requests