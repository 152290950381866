import './App.css';
import {Layout, Menu, MenuProps} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import ServerSelect from "./components/ServerSelect";
import {GlobalStore} from './components/GlobalStore';
import Sider from "antd/es/layout/Sider";
import {useState} from "react";
import Home from './components/Home';
import Mappings from "./components/mappings/Mappings";
import Requests from "./components/requests/Requests";

const items = [
    {key: "home", label: "Home"},
    {key: "mappings", label: "Mappings"},
    {key: "requests", label: "Requests"}
]

function App() {


    const [current, setCurrent] = useState('home');

    const onClick: MenuProps['onClick'] = e => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    let mainContent;
    switch (current) {
        case "home":
            mainContent = <Home/>
            break;
        case "mappings":
            mainContent =  <Mappings/>
            break;
        case "requests":
            mainContent = <Requests/>
            break;
    }

    return (
        <div className="App">
            <GlobalStore>
                <Layout>
                    <Sider>
                        <ServerSelect/>
                    </Sider>
                    <Layout>
                        <Header>
                            <Menu onClick={onClick} selectedKeys={[current]} theme="dark"
                                  mode="horizontal" items={items}></Menu>
                        </Header>
                        <Content>
                            {mainContent}
                        </Content>
                    </Layout>
                </Layout>


            </GlobalStore>
        </div>

    );
}

export default App;
