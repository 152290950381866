import {Select} from "antd";
import React, {useContext} from "react";
import {globalContext} from "./GlobalStore";

function ServerSelect(){

    const { globalState, dispatch } = useContext(globalContext);

    const options = [
        { value: 'NONE', label: 'None', name: '', url: '', port: ''},
        { value: 'LOCAL_K8S', label: 'Local K8s', name: 'Local K8s', url: 'https://wiremock.k8s.local', port: '443' }
    ]

    const handleChange = (value: string) => {
        let found = options.find(element => element.value === value) ?? options[0];

        dispatch({type: "SET_SERVER", payload: found})
    };

    return (
        <div>
            <h2>Pick wiremock</h2>
        <Select onChange={handleChange} options={options} style={{ width: 120 }} defaultValue={globalState.value}/>
        </div>
    )
}

export default ServerSelect;