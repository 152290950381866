import { useContext, useState } from "react";
import { globalContext } from "./../GlobalStore";
import https from 'https'
import documentFail from '../../fixtures/document_failure/4-success-get-va_identities_status.json'
import MappingTable from "./MappingTable";


function Mappings() {

    const { globalState, dispatch } = useContext(globalContext);
    const [mappings, setMappings] = useState<any>(null)
    const [error, setError] = useState<string>('')

    const httpsAgent = new https.Agent({ rejectUnauthorized: false })

    const onClick =  (() => {
        if (globalState.url) {
            console.log("about to call " + globalState.url + ':' + globalState.port + "/__admin/mappings")
            fetch(globalState.url + ':' + globalState.port + "/__admin/mappings")
                .then((response) => response.json())
                .then((data) => setMappings(data.mappings))
                .catch((err) => {
                    console.log("Error getting mappings:")
                    console.log(err.message);
                   });
        }

    })
    const onClickReset =  (() => {
            if (globalState.url) {
                console.log("about to post to " + globalState.url + ':' + globalState.port + "/__admin/mappings/reset")
                fetch(globalState.url + ':' + globalState.port + "/__admin/mappings/reset", {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                })
                    .then(() => onClick())
                    .catch((err) => {
                        console.log("Error reseting mappings:")
                        console.log(err.message);
                       });
            }

        })

        const onClickDocumentFail =  (() => {
            if (globalState.url) {
                console.log("about to post to " + globalState.url + ':' + globalState.port + "/__admin/mappings")
                fetch(globalState.url + ':' + globalState.port + "/__admin/mappings", {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(documentFail),
                })
                    .then((response) => response.json())
                    .then((data) => setMappings(data.mappings))
                    .then(() => onClick())                .catch((err) => {
                        console.log("Error setting document vaidation failure:")
                        console.log(err.message);
                       });
            }

        })

    var result = ""
    if (mappings) {
        result = JSON.stringify(mappings)
    }
    return (
        <div>
            <h1>WIREMOCK TEST MAPPINGS</h1>
            <button onClick={onClick}>Refresh</button>
            <button onClick={onClickReset}>Reset</button>
            <button onClick={onClickDocumentFail}>Document Fail Arm</button>
            
            <MappingTable mappings={mappings}/>
        </div>

    )
}

export default Mappings;