import { ActionType, GlobalStateInterface } from './types';

const Reducer = (state: GlobalStateInterface, action: ActionType): any => {
    switch (action.type) {
        case 'SET_SERVER':
            return {
                ...state,
                value: action.payload.value,
                name: action.payload.name,
                url: action.payload.url,
                port: action.payload.port,
            };
        default:
            return state;
    }
};

export default Reducer;