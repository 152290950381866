import { List, Table } from "antd"
import {components} from "../../types/generated/wiremock-admin"


type RequestTableProps = {
    requests: any[]
}

function RequestTable(props: RequestTableProps) {

    if(props.requests){
        
      return (
          
          <List
          itemLayout="vertical"
          size="large"
          dataSource={props.requests} 
          renderItem={item => (
              <List.Item
                key={item.id}>
                 <List.Item.Meta description={item.request?.method + " " + item.request?.url}/>
                 {JSON.stringify(item.request)}
              </List.Item>
            )}/>
      )
         } else {
            return (<div>No requests</div>)
         }

}

export default RequestTable